<template>
  <b-card
    :img-src="img"
    img-alt="Casino Game"
    style="max-width: 20rem;"
    img-top
    :title="title"
  >
    <b-card-text>
      {{ description }}
    </b-card-text>
    <b-button
      :href="buttonHref"
      v-ripple.400="'rgba(113, 102, 240, 0.15)'"
      variant="outline-primary"
    >
      {{ buttonText }}
    </b-button>
  </b-card>
</template>

<script>
import { BCard, BCardText, BButton } from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'

export default {
  components: {
    BCard,
    BCardText,
    BButton
  },
  props: {
    title: {
      required: true,
      type: [String]
    },
    description: {
      required: false,
      type: [String]
    },
    img: {
      required: true,
      type: [String]
    },
    buttonText: {
      required: false,
      type: [String],
      default: 'รายละเอียด'
    },
    buttonHref: {
      required: false,
      type: [String],
      default: '/'
    }
  },
  directives: {
    Ripple
  }
}
</script>
